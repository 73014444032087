 import { useEffect, useState } from "react";
      
//import mainJs from "../components/script/mainJs";



 
const Buy = () => { 

  const [rate, setRate] = useState('');
  const [bnbPrice, setBnbprice] = useState(0);
  const [btcPrice, setBtcprice] = useState(0);
  const [busdPrice, setBusdprice] = useState(0);
  const [usdtPrice, setUsdtprice] = useState(1);
  
  const [paymentmethod, setPaymentmethod] = useState('bnb');
  const [inputAmount, setInputamount] = useState(0);
  const [highAmount, setHighAmount] = useState(0);
  const [cryptoTopay, setCryptoTopay] = useState(0);
  const [usdtTohigh, setUsdttohigh] = useState(0.6340);
  const [showButton, setShowbutton] = useState(true);
  const wallet = "0x22435B3c8fB160122D04E17C3865B4EeB896bF55";
  const btcWallet = "";
  const ethWallet = "";

  var url = "https://link.trustwallet.com/send/";
  

  async function getapiatabnb() {
      const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD");
      var data = await response.json();
      var price = data.USD - (0.152 * data.USD);
      var price1 = price.toFixed(8);
      console.log('bnb price' + price1);
      setBnbprice(price1);
      
    }

    async function getapiatabtc() {
      const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD");
      var data = await response.json();
      var price = data.USD - (0.152 * data.USD);
      var price2 = price.toFixed(8);
       
        setBtcprice(price2);
    }

    async function getapiatabusd() {
        const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=BUSD&tsyms=USD");
        var data = await response.json();
        var price = data.USD - (0.15 * data.USD);
        var price3 = price.toFixed(8);
       
          setBusdprice(price3);
    }
  
    async function getapiatausdt() {
          
        setUsdtprice(1);
      }

      async function getapiatahigh() {
        const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=PROS&tsyms=USD");
        var data = await response.json();
        var price = data.USD - (0.15 * data.USD);
        var price3 = price.toFixed(4);
         
        setUsdttohigh(price3);
    }

  useEffect(() => {

      //mainJs();
      getapiatabnb();
      getapiatabtc();
      getapiatabusd();
      getapiatausdt();
      getapiatahigh();

  }, []);


  function paymentMethodChange(payMethod){

    if(payMethod == ""){
      setShowbutton(false);
      return false;
    }
    setPaymentmethod(payMethod);
    updateInputAmount(inputAmount);  
   
  }


 function updateInputAmount(amount){

  if(amount < 100){
    setShowbutton(false);
    return false;
  }
  setShowbutton(true);
   setInputamount(amount);
   let myRate = rate;

   if(paymentmethod === "bnb"){
        
    myRate = bnbPrice;

  }else if(paymentmethod === "btc"){
    myRate = btcPrice;

  }else if(paymentmethod === "busd"){
     
    myRate = busdPrice;

  }else if(paymentmethod === "usdt"){
    myRate = usdtPrice;
  }
    var cryptoAmount = (1/myRate) * amount;
    //set crypto amount
    setCryptoTopay(cryptoAmount.toFixed(4));
    //now set high token to receive
    var highrate = 1/usdtTohigh * amount;
    setHighAmount(highrate.toFixed(4)); 
    

 } 

 function buyButton(){
  
    if(paymentmethod === "bnb"){
        var newurl =  url + "?coin=714&address=" + wallet + "&amount=" + cryptoTopay;
       window.location.href = newurl;
    }else if(paymentmethod === "btc"){
      var newurl =  url + "?coin=0&address=" + wallet + "&amount=" + cryptoTopay;
      window.location.href = newurl;
    }else if(paymentmethod === "busd"){
      var newurl =  url + "?coin=20000714&address=" + wallet + "&amount=" + cryptoTopay + "&token_id=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
       window.location.href = newurl;
        
    }else if(paymentmethod === "usdt"){
      
      var newurl =  url + "?coin=20000714&address=" + wallet + "&amount=" + cryptoTopay + "&&token_id=0x55d398326f99059fF775485246999027B3197955";
       window.location.href = newurl;
       
    }else{

       return false;
    }
 
 }
 
  return (
    <> 
    
 
          <section style={{ marginTop: 100 }}>
              <div className="container" style={{ width: "25rem", color: '#15154b' }}>
          
                  <div className="card card-body" >
                    
                    <div className="form-group mt-4">
                      <div id="swapInfo" className="mb-3">
                        <div className="payBalance">
                          <p style={{ fontWeight: "bold", fontSize: 18 }}>Buy PROS</p>
                          
                        </div>
                        <div className="exchangeInfo">
                        <div id="price" className="mt-3"  style={{color: '#15154b' }}>
                          <p>Price</p>
                          <p style={{ fontWeight: "bold" }}>
                            1 PROS =
                            <b style={{ fontSize: 15, color: "#16c784" }}>
                              <span> {usdtTohigh} </span>
                            </b>
                            USDT
                          
                          </p>
                        </div>
                        
                      </div>
                      
                        <p >Pay with </p>
                          <div className="coinMax"  style={{color: '#15154b' }}>
                          
                            <select required="" className="form-control" onChange={(e) => paymentMethodChange(e.target.value)}  style={{color: '#15154b' }}>
                            <option value=""  style={{color: '#15154b' }}>Choose wallet</option>
                              <option value="bnb"  style={{color: '#15154b' }}>BNB</option>
                              <option value="btc"  style={{color: '#15154b' }}>BTC</option>
                              <option value="busd"  style={{color: '#15154b' }}>BUSD</option>
                              <option value="usdt"  style={{color: '#15154b' }}>USDT</option>
                            </select>
                          </div>
                          
                          <div className="coinMax" style={{marginTop: "20px"}}>
                          
                              <input
                                onChange={(e) => updateInputAmount(e.target.value)}
                                required=""
                                id="pay-amount"
                                type="number"
                                className="form-control"
                                placeholder="Amount in USD"
                                style={{ height: 50, marginBottom: 40, display: 'block' }}
                              /> 
                          
                          </div>
                        
                          
                        
                      </div>
                      <div id="obtainInfo" style={{marginBottom: '20px'}}>
                        <div className="payBalance">
                          <p style={{ fontWeight: "bold", fontSize: 18 }}>You Get</p>
                        </div>
                        <hr />
                        <div className="coinMax" >
                          <p style={{display: 'inline'}}>
                            <img src="https://static.tildacdn.com/tild3563-6137-4163-b038-353634613335/favicon-2.ico" width="20px" />
                            <span className="px-4">PROS</span>
                            <i className="fas fa-chevron-down"></i>
                          </p>
                          <p style={{ fontSize: 18, display: 'inline' }}  >
                            {highAmount}
                          </p>
                        </div>
                      </div>
                    
                      <div className="row">
                        <div className="container">
                          <div
                            className="alert alert-success"
                            id="status"
                            style={{ display: "none" }}
                          >
                            No Metamask (or other Web3 Provider) installed
                          </div>
                        </div>
                      </div>
                      {
                        showButton ? <>
                          <button
                        onClick={() => buyButton()}
                        style={{
                        
                          backgroundColor: "#4782FC",
                          border: "none",
                          
                          padding: 10
                        }}
                        className="bt text-white rounded orange-cta-btn ata-secondary"
                        type="button"
                        id="pay-button"
                      >
                        Buy   <img src="https://static.tildacdn.com/tild3563-6137-4163-b038-353634613335/favicon-2.ico" width="20px" />
                          &nbsp; PROS
                      </button>
                        </>: <></>
                      }
                      
                    </div>
                  </div>
              </div>
          </section>

 
 
    </>
  );
};
export default Buy;
