import { useWeb3React } from "@web3-react/core";
//import { Divider } from "antd";

//import { useWindowWidthAndHeight } from "../../hooks/useWindowWidthAndHeight";
import {   TransferEth } from "./components";
 

const DisplayPane: React.FC = () => {
  const {  isActive } = useWeb3React();
  //const [width] = useWindowWidthAndHeight();
 // const isMobile = width <= 768;

  return (
    <div >
      
     
      <div >
        
       

        {isActive && (
          <>
            
          
              
              <TransferEth />
            
          </>
        )}
      </div>
    </div>
  );
};

export default DisplayPane;
