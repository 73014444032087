
import { Link } from "react-router-dom";
import ConnectAccount from "../components/Account/ConnectAccount";
import ChainSelector from "../components/ChainSelector";

const styles = {
    accordion: {
        'a, a:visited, a:hover, a:active': {
            color: '#1E1941',
            border: "0px"
          },
        textAlign: 'left', 
        backgroundColor: "#1E1941", 
        border: "0px",
    }
}
const Nav = () => {


 
  return (
        <>  
                <nav  className="navbar navbar-expand-lg navbar-light shadow-sm"  id="mainNav" style={{backgroundColor: '#0c0c0e'}}>
                    <div className="container px-5">
                    <a className="navbar-brand fw-bold" href="/">
                        <img src="https://static.tildacdn.com/tild3563-6137-4163-b038-353634613335/favicon-2.ico"/>
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarResponsive"
                        aria-controls="navbarResponsive"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <ConnectAccount />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                        <li className="nav-item">
                            <ConnectAccount />
                        </li>
                        </ul>
                    </div>
                    </div>
                </nav>
        </>
  );
};
export default Nav;
