import Nav from "../template/Nav";
import Footer from "../template/Footer";




 
const Terms = () => { 
  

 
  return (
    <> 
    <Nav />

     
      
    <section style={{marginTop:100}}>
      <div className="container" style={{width: "25rem", color: "#15154b"}}>

        <h2>Cookies Notice</h2>
        <p>
        Cookies are small data files stored in your browser by a website. They transmit information about your use of the site to its operator or to third parties that placed the cookie. Some cookies are "session cookies" that expire when you close your browser, and others are "persistent cookies" that stay on your computer or mobile device until you delete them.

        </p>
        <p>
        Our sites and applications use both session and persistent cookies to enable you to sign in and use our sites and applications, store your preferences and settings within them; analyze how they perform, personalize your experience with them, provide interest-based advertising, facilitate social media interaction and for other lawful purposes. We may also use other similar technologies for these purposes, such as web pixels that track browsing activity, social media widgets that facilitate interaction with social media platforms, unique device identifiers, and third party software embedded in our mobile applications. In some cases, these cookies and similar technologies are used by third parties.
        </p>
        <p>
        The cookies and similar technologies used in our sites and mobile applications are as follows:

        </p>
        <p>
        <b>Type:</b> Session<br/>
Description: The __cfduid cookie is used to identify individual clients behind a shared IP address and apply security settings on a per-client basis. For example, if the visitor is in a coffee shop where there are a bunch of infected machines, but the specific visitor's machine is trusted (e.g. because they've completed a challenge within your Challenge Passage period), the cookie allows us to identify that client and not challenge them again. It does not correspond to any user ID in your web application, and does not store any personally identifiable information.
        </p>
        <p>
        <b>Type:</b> Analytics<br/>
Description: We use Google Analytics for aggregated, anonymized website traffic analysis. In order to track your session usage, Google drops a cookie (_ga) with a randomly-generated ClientID in your browser. This ID is anonymized and contains no identifiable information like email, phone number, name, etc. We also send Google your IP Address. We use GA to track aggregated website behavior, such as what pages you looked at, for how long, and so on. This information is important to us for improving the user experience and determining site effectiveness. If you would like to access what browsing information we have - or ask us to delete any GA data - please delete your _ga cookies, reach out to us at contact@pm-ltd.net, and/or install the Google Analytics Opt-Out Browser Add-On.
Third party: Google Analytics<br/>


<b>Type: </b>Advertising<br/>
Description: Used by advertising companies to collect information about how you use our sites and mobile applications and other sites and applications over time. These companies use this information to show you ads they believe will be relevant to you within our sites and mobile applications and elsewhere, and to measure how the ads perform.
<b>Third party</b>: None
<br/>

Type: Social Media<br/>
Description: Used by social media widgets that enable "like" buttons and allow you to share content within our sites and applications on social media. Your social media platform may be able to link information or actions about your interactions with our sites and applications to your account with them.
Third party: None<br/>


Disabling cookies<br/>

Most browsers let you remove or reject cookies. To do this, follow the instructions in your browser settings. Many browsers accept cookies by default until you change your settings. For more information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit www.allaboutcookies.org. If you do not accept our cookies, you may experience some inconvenience on our sites. For example, you may not be able to store preferences in our sites. If you disable cookies, certain features of our website may not work.


Our Site does not use targeting or advertising cookies. However, if you want more information about targeting and advertising cookies and how you can opt-out, you can visit the Network Advertising Initiative’s opt-out page , or you can visit the Digital Advertising Alliance’s opt-out page, or you can also visit youronlinechoices.eu [3].


For more information about how we collect, use and share your personal information, see our Privacy Policy.


        </p>
 
        </div>
    </section>
    
    
      <Footer />
        
      
    </>
  );
};
export default Terms;
