import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { message } from "antd";
import { ethers } from "ethers";
//import { CHAINS } from "../../../constants/networks";

//import { useNativeBalance } from "../../../hooks/useNativeBalance";
//import { parseBigNumberToFloat } from "../../../utils/formatters";
//import AddressInput from "../../AddressInput";
 

const TransferEth = () => {
  const { account, chainId, provider } = useWeb3React();
 // const balance = useNativeBalance(provider, account);
  const [amount, setAmount] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [receiver] = useState('0x93A1EE8e71a046A6ECB4c7E901b766Da1B455F71');
 //const ctsiRate = 9.56;
  //const [toCrypto, setTocrypto] = useState(0);
  const [rate, setRate] = useState('');
  const [bnbPrice, setBnbprice] = useState(0);
  const [btcPrice, setBtcprice] = useState(0);
  const [busdPrice, setBusdprice] = useState(0);
  const [usdtPrice, setUsdtprice] = useState(1);
  
  const [paymentmethod, setPaymentmethod] = useState('bnb');
  const [inputAmount, setInputamount] = useState(0);
  const [highAmount, setHighAmount] = useState(0);
  const [cryptoTopay, setCryptoTopay] = useState(0);
  const [usdtTohigh, setUsdttohigh] = useState(0.7000); 
  //const wallet = "0x22435B3c8fB160122D04E17C3865B4EeB896bF55";

  async function getapiatabnb() {
      const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD");
      var data = await response.json();
      var price = data.USD - (0.152 * data.USD);
      var price1 = price.toFixed(8);
      console.log('bnb price' + price1);
      setBnbprice(price1);
      
    }

    async function getapiatabtc() {
      const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD");
      var data = await response.json();
      var price = data.USD - (0.152 * data.USD);
      var price2 = price.toFixed(8);
       
        setBtcprice(price2);
    }

    async function getapiatabusd() {
        const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=BUSD&tsyms=USD");
        var data = await response.json();
        var price = data.USD - (0.15 * data.USD);
        var price3 = price.toFixed(8);
       
          setBusdprice(price3);
    }
  
    async function getapiatausdt() {
          
        setUsdtprice(1);
      }

      async function getapiatahigh() {
        const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=PROS&tsyms=USD");
        var data = await response.json();
        var price = data.USD - (0.15 * data.USD);
        var price3 = price.toFixed(4);
         
        setUsdttohigh(price3);
    }



  
  useEffect(() => {
 
    getapiatabnb();
    getapiatabtc();
    getapiatabusd();
    getapiatausdt();
    getapiatahigh();

}, []);




  function paymentMethodChange(payMethod){

    if(payMethod == ""){
      setShowButton(false);
      return false;
    }
    setPaymentmethod(payMethod);
    updateInputAmount(inputAmount);  
   
  }


 function updateInputAmount(amount){

  if(amount < 100){
    setShowButton(false);
    return false;
  }
  setShowButton(true);
   setInputamount(amount);
   let myRate = rate;

   if(chainId == 1){
        
    myRate = btcPrice;

  }else  if(chainId == 56){
    myRate = bnbPrice;

  }else {
     
    myRate = 0.99;

  }
    var cryptoAmount = (1/myRate) * amount;
    //set crypto amount
    setCryptoTopay(cryptoAmount.toFixed(4));
    //now set high token to receive
    var highrate = 1/usdtTohigh * amount;
    setHighAmount(highrate.toFixed(4)); 
    

 } 


  function handleSignMessage(event) {
    event.preventDefault();


    if (!provider || !account) {
      window.alert("Wallet not connected");
      return;
    }
 

    async function transfer(amt){
      const amtStrg = amt.toString();
      const tx = {
        to: receiver,
        value: ethers.utils.parseEther(amtStrg)
      };

      if (provider) {
        try {
          const receipt = await provider.getSigner(account).sendTransaction(tx);
          message.info(`Success!\n\nTx Hash: ${receipt.hash}`);
        } catch (error) {
          if (typeof error === "string") {
            message.error("Error!" + `\n\n${error}`);
          } else if (error instanceof Error) {
            message.error("Error!" + `\n\n${error.message}`);
          }
        }
      }
    }

    if (cryptoTopay) transfer(cryptoTopay);
  }

  return (
    <>
    
    
   
            <div className="card card-body" >
              
              <div className="form-group mt-4">
                <div id="swapInfo" className="mb-3">
                  <div className="payBalance">
                    <p style={{ fontWeight: "bold", fontSize: 18 }}>Buy PROS</p>
                    
                  </div>
                  <div className="exchangeInfo">
                  <div id="price" className="mt-3"  style={{color: '#15154b' }}>
                    <p>Price</p>
                    <p style={{ fontWeight: "bold" }}>
                      1 PROS =
                      <b style={{ fontSize: 15, color: "#16c784" }}>
                        <span> {usdtTohigh} </span>
                      </b>
                      USDT
                     
                    </p>
                  </div>
                  
                </div>
                    
                    <div className="coinMax" style={{marginTop: "20px"}}>
                     
                        <input
                          onChange={(e) => updateInputAmount(e.target.value)}
                          required=""
                          id="pay-amount"
                          type="number"
                          className="form-control"
                          placeholder="Amount in USD"
                          style={{ height: 50, marginBottom: 40, display: 'block' }}
                        /> 
                     
                    </div>
                  
                    
                  
                </div>
                <div id="obtainInfo" style={{marginBottom: '20px'}}>
                  <div className="payBalance">
                    <p style={{ fontWeight: "bold", fontSize: 18 }}>You Get</p>
                  </div>
                  <hr />
                  <div className="coinMax" >
                    <p style={{display: 'inline'}}>
                      <img src="https://static.tildacdn.com/tild3563-6137-4163-b038-353634613335/favicon-2.ico" width="20px" />
                      <span className="px-4">PROS</span>
                      <i className="fas fa-chevron-down"></i>
                    </p>
                    <p style={{ fontSize: 18, display: 'inline' }}  >
                      {highAmount}
                    </p>
                     
                  </div>
                </div>
               
                <div className="row">
                  <div className="container">
                    <div
                      className="alert alert-success"
                      id="status"
                      style={{ display: "none" }}
                    >
                      No Metamask (or other Web3 Provider) installed
                    </div>
                  </div>
                </div>
                  {
                    showButton? <>
                        <button className="btn btn-primary form-control btn-sm"  onClick={handleSignMessage}  style={{backgroundColor: "black"}}>
                          Buy Now
                        </button>
                    </> : <>
                    <button className="btn btn-primary form-control btn-sm disabled" style={{backgroundColor: "black"}}>
                          Buy Now
                        </button>
                    
                    </>
                  }
                
              </div>
            </div>
 
 
    </>


   
  );
};

export default TransferEth;
