import Nav from "../template/Nav";
import Footer from "../template/Footer";



 
const Privacy = () => {
 
 
  return (
    <> 
    <Nav />

     
      
    <section style={{marginTop:100}}>
      <div className="container" style={{width: "25rem", color: "#15154b"}}>

        <h2>Privacy Policy</h2>
    <p>
    Welcome to prosper.so (the "Site"). Prosper OÜ ("Prosper" , "we", "us" or "our") is committed to treating the personal and corporate information of our users with respect and sensitivity. Our privacy commitment is described in this privacy policy (the "Privacy Policy"). Prosper provides the Site to you subject to your acceptance of the Privacy Policy.

Prosper is a group of early prediction market researchers who are committed to furthering the adoption, research and academic discoveries within predictive forecasting. Prosper operates educational and user resources for existing and promising applications of predictive software. Prosper makes no money from these web properties. Prosper operates the prosper.so domain name and various social media accounts.

    </p>

    <h4>Cookies notice: What we collect and how we use and share your information</h4>
<p>We may collect session information from you using Cookies.</p>
      <h4>Cookies</h4>
      <p>Cookies are small pieces of information that a website sends to your computer’s hard drive while you are viewing the website. We only use session Cookies (which expires once you close your web browser) to provide information about the number of users accessing the Site. No personal or other type of data is collected.
</p>
<h4>Information choices and changes</h4>
<p>n the unlikely event we collected personal information from you, you may send requests about personal information to our Contact Information below, such as deleting any of your information we may have collected.

You can typically remove and reject cookies from our Site with your browser settings. Many browsers are set to accept cookies until you change your settings. If you remove or reject our cookies, it could affect how our Site works for you.</p>

<h4>A Note About Children</h4>
<p>We do not intentionally gather personal information, such as first and last name, email address, postal address, etc. from visitors who are under the age of 16. If a child under 16 submits personal information to Prosper and we learn that the personal information is the information of a child under 16, we will attempt to delete the information as soon as possible. If you believe that we might have any personal information from a child under 16, please contact us at info@prospernetwork.com</p>
     <p>A Note to Users Outside of the Republic of Estonia. Although we do not collect information from you, by visiting the Site Site and providing us with any information, you acknowledge and agree that the information you provide, including personal information may be used for the purposes identified in the Privacy Policy. In addition, your personal information may be processed in the country in which it was collected and in other countries, including the United States, where laws regarding processing of personal information may be less stringent than the laws in your country. By providing any of your information, you consent to such transfer.
</p>  
       <h4>Contact information</h4>
       <p>
       We welcome your comments or questions about this privacy policy.<br/>

You may also contact us at our address:<br/>

info@prospernetwork.com<br/>

Changes to this privacy policy<br/>

We may change this privacy policy. If we make any changes, we will change the Last Updated date above.

       </p>
        </div>
    </section>
    
    
     
      <Footer />
        
      
    </>
  );
};
export default Privacy;
