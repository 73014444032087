
import { Link } from "react-router-dom";
 
 
const Footer = () => {


 
  return (
        <>  
                 {/* Footer*/}
        <footer className="text-center py-5" style={{ marginTop: 100, backgroundColor: '#15154b' }}>
          <div className="container px-5" style={{backgroundColor: '#15154b'}}>
         
          <Link to="/privacy" style={{color:'white'}}>Privacy policy</Link>   &nbsp; &nbsp; &nbsp;
          <Link to="/terms" style={{color: 'white'}}>Term of Use</Link>  &nbsp; &nbsp; &nbsp;
          <Link to="/rules" style={{color: 'white'}}>Rules</Link> &nbsp; &nbsp; &nbsp;
          <Link to="/cookie" style={{color: 'white'}}>Cookies</Link>  &nbsp; &nbsp; &nbsp;
            <div className="text-white-50 small">
              <div className="mb-2">&copy; 2020 Prosper. All Rights Reserved</div>
             
            </div>
          </div>
        </footer>
        
        </>
  );
};
export default Footer;
