import Nav from "../template/Nav";
import Footer from "../template/Footer";

 
const Rules = () => { 

 
  return (
    <> 
    <Nav />

     
      
    <section style={{marginTop:100}}>
      <div className="container" style={{width: "25rem", color: "#15154b"}}>

        <h2>Rules</h2>
        <p>As a decentralized non-custodial prediction market, Prosper is based on smart contract logic.

There are three types of pools in the Prosper system: 15 minutes, 30 minutes, and 60 minutes. Every day, Prosper smart contract automatically generates 168 pools every day on BSC network.

The predicted price of the new pool is based on moving averages and technical analysis. However, we will implement pools with the custom price as an option.

After the pool is opened you can bet on bulls or bears. If the price (at the moment of the pool end time) is above the starting number, bulls won. If below - bears one. To provide a maximum level of transparency, Prosper uses oracle price feeds to confirm the price. That eliminates the risks of manipulations.

The reward distribution is simple and clear, if Bears/Bulls won, they would split the Bulls/Bears part of the pool based on a percentage of the initial investment.

The reward can only be claimed within a 48 hours period. Once 48 hours have passed, the user is unable to claim the prize.
</p>
 
        </div>
    </section>
    
    
     

       <Footer />
        
      
    </>
  );
};
export default Rules;
