import DisplayPane from "../components/displayPane/DisplayPane"; 
import { Divider } from "antd";
import Nav from "../template/Nav"; 
//import mainJs from "../components/script/mainJs";
import { useWeb3React } from "@web3-react/core";
import Buy from "./Buy";
import Footer from "../template/Footer";



 
const Home = () => {

  const { isActive } = useWeb3React();
  
 
  return (
    <> 
    <Nav />

     
    <section style={{ backgroundColor: '#0c0c0e'}} >
        <div className="container-fluid" style={{ marginBottom: '50px'}}>

          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6" style={{textAlign: 'center'}}>
              <img src="https://static.tildacdn.com/tild6564-6230-4664-b764-303930616261/logo_PROSPER.svg"/>
                  
              <h1 style={{marginTop: '30px', color: '#a88dde'}}>
                LEVERAGE YOUR COMMUNITY'S COLLECTIVE INTELLIGENCE
              </h1>
            </div>
          </div>
        </div>
    </section>


    { isActive ? <>
    
    
    <section style={{marginTop:100}}>
      <div className="container" style={{width: "25rem", color: "#15154b"}}>
              <div style={{ display: "flex", flexDirection: "column", backgroundColor: "white" }} >
                   <DisplayPane />
              </div>

        </div>
    </section>
    
    
    
    </>: <>
    
    <Buy />
    
    
    </>}
     
         
         <Footer />
       

       
      
    </>
  );
};
export default Home;
